import React from "react";
import classnames from "classnames/bind";
import styles from "../../styles/Layout.module.scss";

interface props {
  children: React.ReactNode;
}

const cx = classnames.bind(styles);

const ContainerPage = ({ children }: props) => {
  return <div className={cx("container-without-padding")}>{children}</div>;
};

export default ContainerPage;
