import React from "react";
import styles from "../../../styles/Home.module.scss";
import classnames from "classnames/bind";
import TitleHomeSection from "./titleSection";
import useTranslation from "next-translate/useTranslation";

const cx = classnames.bind(styles);

const WhySectionHome = () => {
  const { t } = useTranslation("home");
  return (
    <div style={{backgroundColor:"#f3f3f3"}}>
      <div className="container">
        <div className={cx("section-home")}>
          <div className={cx("section-home-item")}>
            <div className="col">
              <TitleHomeSection color="blue" title={t("why us?")} />
            </div>
            <div className={cx("item-section")}>
              <div className="col text-capitalize d-flex gap-1 align-items-start">
                <div className="d-flex align-items-center gap-1">
                  <p className="fw-bolder my-auto">01</p>
                  <div className={cx("line")}>&nbsp;</div>
                </div>
                <p>{t("post ads easily and quickly")}</p>
              </div>

              <div className="col text-capitalize d-flex gap-1 align-items-start">
                <div className="d-flex align-items-center gap-1">
                  <p className="fw-bolder my-auto">02</p>
                  <div className={cx("line")}>&nbsp;</div>
                </div>
                <p> {t("user-friendly interface")}</p>
              </div>

              <div className="col text-capitalize d-flex gap-1 align-items-start">
                <div className="d-flex align-items-center gap-1">
                  <p className="fw-bolder my-auto">03</p>
                  <div className={cx("line")}>&nbsp;</div>
                </div>
                <p> {t("free with no cost at all")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhySectionHome;
