import Image from "next/image";
import React from "react";
import TitleHomeSection from "./titleSection";
import classnames from "classnames/bind";
import styles from "../../../styles/Home.module.scss";

const cx = classnames.bind(styles);

interface props {
  imageLink: string;
  title: string;
  description: string;
}

const HomeSectionItem = ({
  description,
  imageLink,
  title,
}: props) => {
  return (
    <div
      className={cx(
        "home-section-content"
      )}
    >
      <div className={cx("image-container")}>
        <Image
          src={imageLink}
          layout="responsive"
          objectFit="contain"
          width={565}
          height={380}
          alt="image"
          quality={75}
          className="w-100"
        />
      </div>
      <div className={cx("description-container")}>
        <TitleHomeSection color="orange" title={title} />
        <p className={`text-justify text-capitalize mwh-6`}>{description}</p>
      </div>
    </div>
  );
};

export default HomeSectionItem;
